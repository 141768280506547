import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { configuration } from '@dpgradio/creative'
import App from './App.vue'

const initialize = async () => {
  try {
    await configuration.retrieveConfigForDetectedStation()
  } catch (error) {
    alert(`
      Could not load configuration.
      - Have you set up a configuration for this application in Dario (see [updateConfigSchema.js])? If you do not want a custom configuration, remove the application name from the [configuration.retrieveConfigForDetectedStation] call in [main.js].
      - Did you provide a station name? (e.g. through the URL parameter 'stationId') If you want to set the station name in code, replace the configuration retrieval call with [configuration.retrieveConfigForStation('<station-name>', 'location-tracker-map')] in [main.js]. 

      ${error}
    `)
  }

  const pinia = createPinia()
  const app = createApp(App)

  app.use(pinia)
  app.mount('#location-tracker-map')
}

initialize()

window.createVueApp = initialize
