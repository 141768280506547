import params from './params'
import getStationColor from './utils/getStationColor.js'

const rootElement = document.getElementById('location-tracker-map')
const dataset = rootElement.dataset

const getAttribute = (name, def = undefined) => dataset[name] || params[name] || def

export const stationId = getAttribute('stationId')
export const trackerConfig = getAttribute('trackerConfig')
export const trackerUuid = getAttribute('trackerUuid')
export const trackerColor = getAttribute('trackerColor', getStationColor(stationId))
export const trackerIcon = getAttribute('trackerIcon')
export const trackerIconSize = getAttribute('trackerIconSize')
export const trackerAnchor = getAttribute('trackerAnchor')
export const maxZoom = getAttribute('maxZoom')
export const padding = getAttribute('padding')
export const zoomDuration = getAttribute('zoomDuration')
export const allowMove = (dataset.allowMove || params.allowMove) !== 'false'
export const styleUrl = getAttribute('styleUrl')
