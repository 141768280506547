<template>
  <div id="map" />
</template>

<script setup>
import maplibregl, { Marker } from 'maplibre-gl'
import 'maplibre-gl/dist/maplibre-gl.css'
import { onMounted, ref, watch } from 'vue'
import { useLocationStore } from '../stores/locations.js'
import { calculateBottomHalfBoundingBox } from '../utils/boundingBox.js'

const props = defineProps({
  trackers: {
    type: Array,
    required: true,
  },
  maxZoom: {
    type: Number,
    default: 18,
  },
  padding: {
    type: Number,
    default: 50,
  },
  zoomDuration: {
    type: Number,
    default: 1000,
  },
  allowMove: {
    type: Boolean,
    default: true,
  },
  styleUrl: {
    type: String,
    default: 'https://tiles.openfreemap.org/styles/positron',
  },
})

const locations = useLocationStore()
const markers = ref({})
const hasMoved = ref(false)

const map = ref()

onMounted(() => {
  map.value = new maplibregl.Map({
    style: props.styleUrl,
    container: 'map',
    attributionControl: false,
    dragPan: props.allowMove,
  })

  map.value.on('load', createMarkers)

  map.value.on('dragend', () => {
    hasMoved.value = true
  })
})

const createMarkers = () => {
  Object.entries(markers.value).forEach((_, marker) => marker.remove())

  markers.value = Object.fromEntries(
    props.trackers.map((tracker) => {
      let el = undefined

      if (tracker.icon) {
        const iconSize = tracker.iconSize ?? [20, 20]

        el = document.createElement('div')
        el.className = 'marker'
        el.style.backgroundImage = `url(${tracker.icon})`
        el.style.width = `${iconSize[0]}px`
        el.style.height = `${iconSize[1]}px`
      }

      return [tracker.uuid, new Marker({ element: el, color: tracker.color, anchor: tracker.anchor })]
    })
  )

  updateMarkers()
}

const updateMarkers = () => {
  Object.entries(markers.value).forEach(([uuid, marker]) => {
    const currentLocation = locations.trackers?.[uuid]?.[0]

    if (!currentLocation) {
      return
    }

    const latLong = [parseFloat(currentLocation.long), parseFloat(currentLocation.lat)]

    marker.setLngLat(latLong).addTo(map.value)
  })

  centerMap()
}

const centerMap = () => {
  if (hasMoved.value) {
    return
  }

  const longLats = Object.values(markers.value)
    .map((marker) => marker.getLngLat())
    .map((latLong) => [latLong.lng, latLong.lat])

  if (!longLats.length) {
    return
  }

  map.value.fitBounds(calculateBottomHalfBoundingBox(longLats), {
    maxZoom: props.maxZoom,
    padding: props.padding,
    duration: props.zoomDuration,
  })
}

watch(() => props.trackers, createMarkers)

watch(() => locations.trackers, updateMarkers, { deep: true })

watch(
  () => hasMoved.value,
  (newHasMoved) => {
    if (!newHasMoved) {
      return
    }

    setTimeout(() => {
      hasMoved.value = false
      centerMap()
    }, 10000)
  }
)
</script>

<style lang="scss">
#map {
  height: 100%;
  width: 100%;
}

.marker {
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
}
</style>
