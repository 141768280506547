<script setup>
import Map from './components/Map.vue'
import {
  allowMove,
  maxZoom,
  padding,
  styleUrl,
  trackerAnchor,
  trackerColor,
  trackerConfig,
  trackerIcon,
  trackerIconSize,
  trackerUuid,
  zoomDuration,
} from './settings.js'
import { useLocationStore } from './stores/locations.js'

const getTrackers = () => {
  if (trackerConfig) {
    try {
      return JSON.parse(trackerConfig).map((tracker) => ({
        uuid: tracker.uuid,
        icon: tracker.icon ?? trackerIcon,
        iconSize: tracker.iconSize ?? trackerIconSize,
        color: tracker.color ?? trackerColor,
        anchor: tracker.anchor ?? trackerAnchor,
      }))
    } catch (error) {
      console.warn('Failed to parse tracker config:', error)
    }
  }

  if (trackerUuid) {
    return [{ uuid: trackerUuid, icon: trackerIcon, color: trackerColor }]
  }

  console.warn('No tracker config found')
  return []
}

const trackers = getTrackers()

const locations = useLocationStore()
locations.initTrackers(trackers.map((tracker) => tracker.uuid))
</script>

<template>
  <Map
    v-if="trackers.length"
    :trackers="trackers"
    :maxZoom="maxZoom"
    :padding="padding"
    :zoomDuration="zoomDuration"
    :allowMove="allowMove"
    :styleUrl="styleUrl"
  />
  <div v-else class="no-trackers-error">No trackers specified</div>
</template>

<style lang="scss">
.no-trackers-error {
  font-family: sans-serif;

  border: 1px solid red;
  padding: 1em;
  margin: 1em;
  border-radius: 0.5em;
  color: red;
  font-weight: bold;
  text-align: center;
  background-color: #ffe7e7;
}
</style>
