export default (stationId) => {
  console.log(stationId)

  switch (stationId) {
    case 'qmusic_be':
    case 'qmusic_nl':
      return '#ed3524'
    case 'joe_be':
    case 'joe_nl':
      return '#1e64c8'
    case 'willy_be':
      return '#141414'
    default:
      return undefined
  }
}
