import { defineStore } from 'pinia'
import { api, socket } from '@dpgradio/creative'
import { configuration } from '@dpgradio/creative'

export const useLocationStore = defineStore('location', {
  state: () => ({
    trackers: {},
  }),

  actions: {
    async initTrackers(trackerUuids) {
      this.listenToSockets(trackerUuids)
      await this.fetchTrackers(trackerUuids)
    },

    async fetchTrackers(trackerUuids) {
      this.trackers = Object.fromEntries(
        await Promise.all(
          trackerUuids.map(async (tracker) => [
            tracker,
            await api.request().get(`/location_trackers/${tracker}/locations?limit=1`),
          ])
        )
      )
    },

    listenToSockets(trackerUuids) {
      const stationSocket = socket.connect(configuration.stationId)

      trackerUuids.forEach((tracker) =>
        stationSocket
          .subscribe({ location_tracker: tracker })
          .on('new_location', (data) => this.trackers[tracker].unshift(data.location))
      )
    },
  },
})
